/*

    App Init script copy for our test runners.

    This script is a plain copy of the configuration parts of our main.js file, to load required dependencies for our functional tests.
    TODO: This should be combined into a single script to be handled a bit more cleanly!!!

*/
let debug       = false;
myApp           = {}; //jshint ignore:line

// Main Entry Point
myApp.init = function () {
    if(debug) console.log('Initialize App Config');

    // Return if we have an already running window instance, or run a web based version
    if(myApp.nativeUI !== undefined || typeof nw === "undefined")
        return;

    // Set our global app reference
    nw.global.manifest = nw.App.manifest;

    // Load our Application config
    require('./configHandler.js');
    myApp.configHandler.init();
};

// Init our Worker
myApp.init();
