/*

 Config Handler

 Loads the project specific configuration for our app

 */
let debug               = false;

const fs                = nw.require('fs');
const path              = nw.require('path');
const AutoLaunch        = nw.require('auto-launch');
const preferenceFile    = path.normalize(nw.App.dataPath + '/config.json');
myApp.configHandler     = {}; //jshint ignore:line

// Main Entry Point
myApp.configHandler.init = function () {
    if (debug) console.log('Load Configuration Files!');

    myApp.configHandler.loadPreferences();
    myApp.configHandler.loadDataConfig();

    nw.global.savePreferences   = myApp.configHandler.savePreferences;
    //
    // Dev Only Item
    if(myApp.isSDKBuild) {
        nw.global.developmentMode   = true;
        nw.global.loadDataConfig    = myApp.configHandler.loadDataConfig;
        nw.global.saveDataConfig    = myApp.configHandler.saveDataConfig;
    }
};


myApp.configHandler.loadPreferences = function () {
    if (debug) console.log('Load Preferences');

    fs.exists(preferenceFile, function(exists) {
        if (exists) {
            fs.readFile(preferenceFile, function (err, data) {

                if( err === null ) {
                    const json              = JSON.parse(data);
                    nw.global.preferences   = json;

                    if(debug) console.log('Current Config:', json);
                }
                else if(debug) console.log('Err:', err);

            });
        }
        else {
            if(debug) console.log('Found no preferences file. Creating Default Template!');

            myApp.configHandler.updatePreferences();
        }
    });
};

myApp.configHandler.savePreferences = function () {
    if (debug) console.log('Save Preferences');
    myApp.configHandler.updatePreferences( nw.global.preferences );
};

myApp.configHandler.updatePreferences = function (data) {
    if (debug) console.log('Update Preferences');

    if (data === undefined) {
        if (debug) console.log('Got no preferences! Creating Defaults!');

        //
        // Default Preferences, keep in sync with actual app data
        data = {
            autostart: nw.App.manifest.config.autostart,
            autoUpdate: nw.App.manifest.config.autoUpdate,
            rememberWindowPosition: true,
            kiosk: nw.App.manifest.config.kiosk
        };
    }
    nw.global.preferences   = data;

    fs.writeFile(preferenceFile, JSON.stringify(data, null, 2), 'utf8', function(err) {
        if(err) {
            if(debug) console.log('Err:', err);
            return console.log(err);
        }
        if(debug) console.log('The file was saved!');
    });


    const launcher = new AutoLaunch({
        name: nw.App.manifest.name + ( debug ? '-Dev' : '')
    });

    // Check if platform is supported
    if(data.autostart) {
        if(debug) console.log('Enable Auto Start');
        launcher.enable().catch(function(err){
            if(debug) console.log('Error during autostart update: ', err );
        });
    }
    else {
        if(debug) console.log('Disable Auto Start');
        launcher.disable().catch(function(err){
            if(debug) console.log('Error during autostart update: ', err );
        });
    }
};

myApp.configHandler.loadDataConfig = function () {
    if (debug) console.log('Load Data Config');
    const file  = './config.json';

    fs.readFile(file, function (err, data) {

        if( err === null ) {
            const json      = JSON.parse(data);
            nw.global.config    = json;

            if(debug) console.log('Current Data Config:', json);
        }
        else if(debug) console.log('Err:', err);

    });
};

myApp.configHandler.saveDataConfig = function (data) {
    if (debug) console.log('Save Data Config');
    const file  = './config.json';

    if (data === undefined) {
        if (debug) console.log('Got no data!');
        return;
    }

    fs.writeFile( file, JSON.stringify(data, null, 2), 'utf8', function(err) {
        if(err) {
            if(debug) console.log('Err:', err);
            return console.log(err);
        }
        if(debug) console.log('The file was saved!');
    });
};
